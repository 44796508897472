.socialLogos-container-floating{
    position: fixed; /* Makes the container stay in place while scrolling */
    top: 70%; /* Centers the container vertically */
    left:  0; /* Positions the container on the left side of the screen */
    transform: translateY(-50%); /* Adjusts the container to be vertically centered */
    display: flex;
    flex-direction: column; /* Arranges the icons vertically */
    align-items: center; /* Centers the icons horizontally */
    padding: 1rem;
    z-index: 1000; /* Ensures the container stays on top of other content */
}

.socialLogos-container {
    justify-content: space-around;
    text-align: center;
    margin: auto;
    display: inline-flex;
    flex-wrap: wrap;
}

.logoGroup-floating {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px; /* Adds some space between icons */
}

.logoGroup {
    display: flex;
    gap: 5px; /* Adds some space between icons */
}

.logo {
    border: solid white;
    border-radius: 2rem;
    background-color: white;
    opacity: 0.7; /* Makes the icons slightly translucent */
    transition: opacity 0.3s; /* Smooth transition for opacity changes */
}

.logo:hover {
    opacity: 1; /* Fully opaque when hovered */
}

@media screen and (max-width: 500px) {
    .socialLogos-container-floating {
        left: auto;
        right: 0; /* Moves the container to the right side on small screens */
        padding: 1rem;
    }

    .socialLogos-container {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}
