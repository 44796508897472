.video-responsive {
    max-width: 32rem;
    justify-content: center;
    height: 18rem;
    margin: auto;
    flex: 1 1 auto;
    padding: .2rem;
    scale: 90%;
}
.video-responsive iframe {
    height: 100%;
    width: 100%;
}