@import url('https://fonts.googleapis.com/css2?family=Anton&family=Exo:ital,wght@0,100..900;1,100..900&family=Orbitron:wght@400..900&family=Staatliches&display=swap');
body {
    font-family: "Staatliches", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-style: normal;
  }

h1, h2, h3 {
    font-family: "Staatliches", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-style: normal;
}


.Photo {
    justify-content: center;
    max-width: 70rem;
    margin: auto;
    text-align: center;
    position: relative;
    perspective: 1000px;
}

.Img {
    height: 50%;
    width: 50%;
    padding: 0.5rem;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform 0.3s;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.Container {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Button {
    margin-top: 30px;
    background-color: #000000;
    color: #ffffff;
    padding: 10px 20px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s, transform 0.3s;
    font-family: "Staatliches", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-style: normal;
    font-size: 60px;
    border: 2px solid #fff;
    display: inline-block;
    margin-bottom: 400px;
}

.Img:hover {
    transform: rotateZ(5deg);
}

.Button:hover {
    background-color: #353535; 
    transform: scale(1.1); 
}
