.live-music-section {
  padding: 50px 5%; /* Add padding to keep content away from the edges */
  background-color: black;
  color: white;
  text-align: center;
}

.live-music-title {
  font-size: 128px;
  font-weight: bold;
  color: #fff !important;
  margin-top: 200px;
  margin-bottom: 150px;
  padding: 10px 20px;
  border: 2px solid #fff;
  display: inline-block;
}

.video-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  cursor: pointer;
}

.teaser-video {
  width: 100%;
  height: auto;
  border: 3px solid white;
}

.video-logo-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo-image {
  max-width: 80%; /* Ensure it scales within the container */
  width: 500px; /* Default width */
  height: auto;
}

/* Centers the headers and ensures they are full width */
.show-headers {
  font-size: 122px;
  font-weight: bold;
  color: #fff !important;
  margin-top: 200px;
  margin-bottom: 150px;
  margin-left: 130px;
  margin-right: 130px;
  padding: 10px 20px;
  border: 2px solid #fff;
  display: block;
  width: 100%; /* Make sure it's 100% to center with respect to the section */
  text-align: center;
}

/* Flyer container, ensuring items wrap and are centered */
.upcoming-shows, .previous-shows {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Centers the flyers horizontally */
  gap: 40px; /* Adds space between the flyers */
  margin-top: 20px;
  padding: 0 2%; /* Add padding to keep flyers away from screen edges */
  font-size: 72px;
}


.show {
  text-align: center;
  flex-basis: calc(50% - 40px); /* Each flyer takes up half of the row (two flyers per row) */
  box-sizing: border-box; /* Include padding/margin in element width */
  max-width: 500px; /* Set max-width to control the size */
  margin-bottom: 40px; /* Space between rows */
}

/* Makes the flyer images responsive and centered */
.flyer-img {
  width: 100%; /* Ensure flyer takes up full width of the container */
  height: auto;
  margin-bottom: 10px;
}

/* Style the buttons and ticket-free text */
.ticket-button, .ticket-free {
  margin-top: 10px;
  background-color: #000000;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, transform 0.3s;
  font-family: "Staatliches", sans-serif;
  font-weight: 600;
  border: 2px solid #fff;
  display: inline-block;
}

/* Add hover effects to buttons */
.ticket-button:hover, .ticket-free:hover {
  background-color: #353535;
  transform: scale(1.1);
}

p {
  /* font-size: 100px; */
}