.carousel-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: black; /* Black background for the container */
}

.slide-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%; /* Ensures the images take the full height */

}

.image-container {
  flex: 0 0 100%; /* Each image takes up 100% of the container width */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Black background for the image box */
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain; /* Ensure the image fits within the container */
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent; /* Remove background entirely */
  border: none; /* Remove any border */
  color: rgba(255, 255, 255, 0.6); /* Slightly opaque white for the arrow */
  font-size: 3rem;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s ease;
  font-size: 80px;
}

.arrow-button:hover {
  color: rgba(255, 255, 255, 1); /* Full color on hover */
}

.arrow-button.left {
  left: 50px; /* Adjust positioning */
}

.arrow-button.right {
  right: 50px; /* Adjust positioning */
}

.arrow-button:focus {
  outline: none;
}
