@import url('https://fonts.googleapis.com/css2?family=Anton&family=Exo:ital,wght@0,100..900;1,100..900&family=Orbitron:wght@400..900&family=Staatliches&display=swap');


.epk-bio {
    scale: 80%;
    padding: 1.5rem;
    border-radius: 25px;
    border: .2rem solid #000000;
    background-color: rgba(0, 0, 0, 0.4);
    /*blur effect*/
    /*background: rgba(0, 0, 0, 0.35);*/
    /*border-radius: 16px;*/
    /*box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);*/
    /*backdrop-filter: blur(5px);*/
    /*-webkit-backdrop-filter: blur(5px);*/
    /*border: .2rem solid rgba(0, 0, 0, 0.5);*/
    justify-content: center;
    max-width: 80rem;
    margin: auto;
    font-family: "Staatliches", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-style: normal;
}

@media screen and (max-width: 500px) {
    .epk-bio {
        scale: 95%;
    }
}

.bioImg {
    height: 15rem;
    width: 15rem;
    border-radius: 30%;
    border: .3rem solid #eaeaca;
    background-color: #f6ec82;
    padding: .5rem;
}

.bioTxt {
    text-align: left;
    font-size: 1.2rem;
    padding: 0 0 .5rem 1.1rem;
    color: white;
    font-family: "Trebuchet MS", sans-serif;
    max-width: 50rem;
}

@media screen and (max-width: 500px) {
    .bioTxt {
        font-size: 1.0rem;
        padding-left: 0.3rem;
    }
}

.bioImgTxtDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

h1 {
    text-align: center;
    color: white;
}
