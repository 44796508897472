.EPKStats {
    justify-content: center;
    max-width: 20rem;
    margin: 0 auto;
}

.EPKStats-text {
    text-align: center;
    font-size: 1.2rem;
    color: white;
    font-family: "Trebuchet MS", sans-serif;
    margin: auto;
    padding: 0 .7rem .7rem;
    /*border: .1rem solid #000000;*/
}

.EPKStats a {
    text-align: center;
    font-size: 1.3rem;
    color: #d5f8e6;
    font-family: "Trebuchet MS", sans-serif;
    margin: auto;
    padding: 0 .7rem .7rem;
    text-decoration: none;
    font-weight: bold;
}

.EPKStats h1{
    margin-top: 3rem;
    margin-bottom: 0;
}

.statsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}


.EPKStats-sub {
    color: white;
    margin-top: 0;
}

.greenArrow {
    width: 1rem;
    height: 1rem;
    padding: 0;
}
.tiktokSmall {
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
}
.spotifySmall {
    width: .8rem;
    height: .8rem;
    padding: 0;
}