.apple-responsive {
    max-width: 32rem;
    justify-content: center;
    margin: auto;
    height: 25rem;
    scale: 90%;
}
.apple-responsive iframe {
    height: 100%;
    width: 100%;
}