.PressPhotos{
    justify-content: center;
    max-width: 70rem;
    margin: auto;
    text-align: center;
}

.epk-images{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pressImg {
    height: 30%;
    width: 30%;
    padding: 0.2rem;
    cursor: pointer;
}

.pressImg:hover, .pressImg1:hover, .pressImg4:hover {
    background-color: #11ddff;
}

.PressPhotos h1 {
    margin-bottom: 0;
}

.PressPhotos p {
    color: white;
    margin-top: 0;
}
