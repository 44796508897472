.featuredOn {
    justify-content: center;
    max-width: 38rem;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}

.featuredOnTxt {
    font-size: 1rem;
    color: white;
    font-family: "Trebuchet MS", sans-serif;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: .5rem;
    /*border: .1rem solid #000000;*/
}

.featuredOnTxt a{
    color: white;
}

.featuredOn h1 {
    text-align: center;
    color: white;
    margin-top: 3rem;
}

.iconBulletPoint {
    border: solid white;
    border-radius: 2rem;
    background-color: white;
    scale: 70%;
}

.bulletList {
    text-align: left;
    display: inline-block;
    justify-content: center;
    margin: auto;
}

@media screen and (max-width: 500px) {
    .featuredOnTxt {
        font-size: .7rem;
    }

    .featuredOn h1 {
        margin-top: 2rem;
    }
}