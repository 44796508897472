* {
}

body {

    font-family: "Trebuchet MS", sans-serif;
    margin: 0 10px;
}

.audio-player {
    text-align: left;
    max-width: 45rem;
    margin: 0 auto;
    padding: 1rem;
    scale: 80%;
    flex: 1 1 auto;
}

.current-player {
    display: flex;

}

.audio-author-text {
    color: #ffffff;
    margin-top: .5rem;
}

.title {
    color: #ffffff;
    font-weight: 100;
    font-size: 2rem;
    margin-bottom: 0;
    margin-top: 0;
    text-decoration: none;
}

.title:hover {
    color: #c2fdfd;
}


.audio-image {
    width: 7rem;
    height: 7rem;
    background: #1e1e1e;
}


.audio-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

.right-side-of-coverart {
    padding: 1rem;
    width: 75%;
}


.controls-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.controls button, .volume button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: WHITE;
}

button svg {
    font-size: 20px;
}

.volume {
    display: flex;
    align-items: center;
}

.volume button {
    margin: 0;
}

.progress {
    display: flex;
    align-items: center;
    gap: .5rem;
    width: 100%;
    margin-top: -1.5rem;
}

input[type='range'] {
    -webkit-appearance: none;
    width: 150%;
    height: .5rem;
    border-radius: .1rem;
    border: .02rem solid #464545;
    background: #a8a8a8 linear-gradient(#ffffff, #d5fffd) no-repeat;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    border-radius: 20%;
    background: #d5f8e6;
    cursor: pointer;
    box-shadow: 0 0 .16rem 0 #555;
}

input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

/* time */
.time {
    color: #ffffff;
    font-size: 11px;
    line-height: 46px;
    margin-right: .2rem;
    cursor: pointer;
}


/* TrackList */
.songsList {
    display: flex;
    padding: .2rem;
    border: .1rem solid;
    border-radius: .4rem;
    background: rgba(0,0,0,.2);
    cursor: pointer;
}

.songsList-active, .songsList:hover {
    display: flex;
    padding: .2rem;
    border: .1rem solid #cefff0;
    border-radius: .4rem;
    background: rgba(0,0,0,.6);;
    cursor: pointer;
}

.songsList img, .songsList-active img {
    width: 3rem;
    height: 3rem;
    background: #1e1e1e;
}

.songsList p, .songsList-active p {
    color: #ffffff;
    font-weight: 550;
    padding-left: .4rem;

}

.songsListNumber {
    margin-right: .4rem;
}

@media screen and (max-width: 500px) {
    .audio-player {
        scale: 95%;
        border-radius: 25px;
        border: .2rem solid #000000;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .current-player {
        display: initial;
        text-align: center;
    }

    .title {
        font-size: 1.4rem;
    }

    .right-side-of-coverart {
        width: 100%;
        padding-left: 0;
    }

    .audio-image {
        margin: auto;
        width: 10rem;
        height: 10rem;
    }
}