.footer {
    background-color: black;
    color: white;
    text-align: center;
    padding: 40px 0;
    width: 100%;
    font-size: 15px;
    /* margin-top: 40px; Add some space before the footer */
    padding-bottom: 60px;
  }
  
  .footer p {
    margin: 0;
  }
  