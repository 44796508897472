/* Container */

.showsList-Container {
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
}

/* Show List */
.showsList {
    border: 1px solid #000000;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;   /* horizontal spacing */
    row-gap: 0.1rem;
    justify-content: center;
}

/* Show Item */
.showsList-text {
    color: white;
}

.showsList-sold {
    color: rgb(145, 255, 240);
}

@media (max-width: 600px) {
    .showsList-sold {
        margin-top: 0;
    }
}

/* No Shows Available */
.text-center {
    text-align: center;
}

.text-gray-500 {
    color: #6b7280;
}

/* Modal */
.fixed {
    position: fixed;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.bg-black {
    background-color: black;
}

.bg-opacity-50 {
    opacity: 0.5;
}

.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.p-6 {
    padding: 1.5rem;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.max-w-lg {
    max-width: 32rem;
}

.w-full {
    width: 100%;
}

/* Close Button */
.absolute {
    position: absolute;
}

.top-3 {
    top: 0.75rem;
}

.right-3 {
    right: 0.75rem;
}

.text-gray-500 {
    color: #6b7280;
}

.text-gray-500:hover {
    color: #1f2937;
}

.text-xl {
    font-size: 1.25rem;
}

/* Modal Title */
.text-2xl {
    font-size: 1.5rem;
    font-weight: bold;
}

.mb-4 {
    margin-bottom: 1rem;
}

/* Performance Images */
.grid {
    display: grid;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gap-2 {
    gap: 0.5rem;
}

.performanceImg {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    object-fit: cover;
}
