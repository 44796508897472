.latestPerformance {
    scale: 98%;
    padding: 1.5rem;
    border-radius: 25px;
    border: .2rem solid #000000;
    background-color: rgba(0, 255, 216, 0.1);
    /*blur effect*/
    /*background: rgba(0, 0, 0, 0.35);*/
    /*border-radius: 16px;*/
    /*box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);*/
    /*backdrop-filter: blur(5px);*/
    /*-webkit-backdrop-filter: blur(5px);*/
    /*border: .2rem solid rgba(0, 0, 0, 0.5);*/
    justify-content: center;
    max-width: 50rem;
    text-align: center;
    margin: 2rem auto 0;
}

.locationIcon {
    width: 1rem;
    height: 1rem;
    padding: 0;
    color: white;
}

.performance-images{
    display: block;
    flex-wrap: wrap;
    justify-content: center;
}

.performanceImg {
    height: 30%;
    width: 30%;
    padding: 0.2rem;
    cursor: pointer;

}

.performanceImg:hover {
    background-color: #11ddff;
}

.listContainer {
    text-align: left;
    font-size: 1rem;
    color: white;
    font-family: "Trebuchet MS", sans-serif;
    max-width: 75%;
    /*background-color: red;*/

}

.latestPerformance h1{
    margin-bottom: 0;
}

.latestPerformance h4, .latestPerformance h5 {
    color: white;
    margin-top: .5rem;
    padding-top: 0;
    margin-bottom: 0;
}

.latestPerformance h5{
    margin-bottom: 1rem;
}

.latestPerformance-featuring a{
    color: #d5f8e6;
    text-decoration: none;
}

.latestPerformance-press a{
    color: #d5f8e6;
}

.whiteLink {
    color: white;
    text-decoration: none;
}
.whiteLink:hover {
    color: #e5e2e2;
}


@media screen and (max-width: 500px) {
    .featuredOnTxt {
        font-size: .7rem;
    }

    .featuredOn h1 {
        margin-top: 2rem;
    }
}