.popSongs {
    scale: 80%;
    justify-content: center;
    max-width: 40rem;
    margin: 0 auto;
}

.popSongsTxt {
    text-align: left;
    font-size: 1.4rem;
    padding: 1.3rem;
    color: white;
    font-family: "Trebuchet MS", sans-serif;
    /*border: .1rem solid #000000;*/
}

@media screen and (max-width: 500px) {
    .popSongs {
        scale: 95%;
    }

    .popSongsTxt     {
        font-size: 1.0rem;
    }
}

h1 {
    text-align: center;
    color: white;
}